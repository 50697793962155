<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-layers mr-2"></i>
              <strong>
                Parcelle
                <span v-if="parcelData">
                  ({{parcelData.name}})
                </span>
              </strong>
              <div class="card-header-actions" v-if="canCreate">
                <b-button variant="primary" @click="newProduction">
                  <i class="fa fa-plus"></i>
                  Créer une production
                </b-button>
              </div>
            </div>
            <div class="text-center" v-if="fetchingProductionData">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="productionFound || creating">
              <b-modal :title="addModalTitle" class="modal-primary" v-model="addNodeModal" @hide="onAddModalClose">
                <b-form>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

                  <b-input-group v-if="toAdd.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in toAdd">
                      {{item.name}}
                      <b-link @click.prevent="removeItem(item)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center" v-else>{{noItemText}}</p>

                  <b-input-group class="mb-3 mt-3">
                    <b-autocomplete-input :placeholder="searchPlaceholder" v-model="search"
                                          :data="searchAutocompleteData" class="autocomplete"
                                          @hit="submitSearch($event)" ref="searchAutocompleteInput"
                                          :serializer="item => item.name"
                                          :minMatchingChars=0>
                      <template slot="append">
                        <b-button variant="primary" @click.prevent="submitSearch()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                </b-form>

                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAdd" :fetching=false>
                    Valider
                  </button-spinner>
                  <b-button variant="secondary" @click="addNodeModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              <b-col class="b-r-1">
                <b-form-select v-model="parcelSelected" :options="listParcels" class="mb-3" id="parcel-list">
                  <!-- This slot appears above the options from 'options' prop -->
                  <template slot="first">
                    <option :value="null" disabled>-- Selectionnez votre parcelle --</option>
                  </template>
                </b-form-select>
                <b-tree-view
                  :data="treeData"
                  :contextMenuItems=ctxMenuItems
                  :showIcons=true
                  :renameNodeOnDblClick=false
                  @nodeSelect="onNodeSelect"
                  @contextMenuItemSelect="onCtxMenuSelect"
                  nodeLabelProp=text
                  prependIconClass=fa
                  defaultIconClass="fa-cog">
                </b-tree-view>
              </b-col>
              <b-col v-if="cultureSelected">
                <h5>{{selectedNodeData.text}}</h5>
              </b-col>
              <b-col v-else-if="cultureStepSelected">
                <h5>{{selectedNodeData.text}}</h5>

                <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                    {{selectedNodeData.status.name}}
                  </b-badge>
                </b-input-group>

                <div class="w-100 text-center">
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEndStep" :fetching=fetchingSaveAndLaunch>
                    Clôturer l'étape
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4" @click=onStartStep
                                  :fetching=fetchingStartStep>
                    Démarrer l'étape
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else-if="activitySelected">
                <h5>{{selectedNodeData.text}}</h5>

                <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                    {{selectedNodeData.status.name}}
                  </b-badge>
                </b-input-group>

                <c-input container-class="mb-3" type="quantity" label="Durée de l'activité" placeholder="Ex: 12"
                         v-model="activityDuration" unit="Jour(s)" :state="activityDurationState">
                  Veuillez spécifier une durée
                </c-input>

                <c-input container-class="mb-3" type="quantity" label="Coût de l'activité" placeholder="Ex: 50000"
                         v-model="activityCost" :unit="exploitationCurrency + ' / pers.'" :state="activityCostState">
                  Veuillez spécifier une durée
                </c-input>

                <b-input-group v-if="activityStaff.length">
                  <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in activityStaff">
                    {{item.name}}
                    <b-link @click.prevent="removeStaff(item)">
                      <i class="fa fa-remove text-danger"></i>
                    </b-link>
                  </b-badge>
                </b-input-group>
                <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>

                <b-input-group class="mb-3">
                  <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                        :data="staffAutocompleteData" class="autocomplete"
                                        @hit="addStaff($event)" ref="staffAutocompleteInput"
                                        :serializer="item => item.name"
                                        :minMatchingChars=0>
                    <template slot="append">
                      <b-button variant="primary" @click.prevent="addStaff()">
                        Ajouter
                      </b-button>
                    </template>
                  </b-autocomplete-input>
                </b-input-group>

                <b-input-group class="mb-3" v-for="(input, i) in activityInputs">
                  <b-input-group-prepend class="w-75">
                    <b-input-group-text class="w-100">{{input.inputName}}</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" class="form-control" placeholder="Qté"
                                v-model="input.quantity" :state="activityInputQuantityState[i]"
                                :aria-describedby="`activity-input-quantity-${i}-feedback`" min="0" step="1"/>
                  <b-input-group-append class="unit">
                    <b-input-group-text class="w-100">{{input.unit}}</b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback :id="`activity-input-quantity-${i}-feedback`">
                    Veuillez entrer une quantité
                  </b-form-invalid-feedback>
                </b-input-group>

                <b-input-group class="mb-3" v-for="(equipment, i) in activityEquipments">
                  <b-input-group-prepend class="w-75">
                    <b-input-group-text class="w-100">{{equipment.equipmentName}}</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" class="form-control" placeholder="Qté"
                                v-model="equipment.quantity" :state="activityEquipmentQuantityState[i]"
                                :aria-describedby="`activity-equipment-quantity-${i}-feedback`" min="0" step="1"/>
                  <b-input-group-append class="unit">
                    <b-input-group-text class="w-100">{{equipment.unit}}</b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback :id="`activity-equipment-quantity-${i}-feedback`">
                    Veuillez entrer une quantité
                  </b-form-invalid-feedback>
                </b-input-group>

                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onUpdateActivity"
                                  :fetching=false>
                    Enregistrer
                  </button-spinner>
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEndActivity" :fetching=fetchingStartActivity>
                    Clôturer l'activité
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click="onStartActivity"
                                  :fetching=fetchingStartActivity>
                    Démarrer l'activité
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else>
                <h5>Production</h5>

                <b-input-group class="mb-3 mt-3" v-if="productionStatus">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="productionStatus.variant">
                    {{productionStatus.name}}
                  </b-badge>
                </b-input-group>

                <c-input container-class="mb-3 mt-3" label="Nom de la production" placeholder="Ex: Cacao 2019"
                         v-model="productionName" :state="productionNameState">
                  Veuillez saisir un nom
                </c-input>

                <c-input container-class="mb-3" type="textarea" label="Description"
                         placeholder="Entrez une description..." v-model="productionDescription">
                </c-input>

                <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="productionStartDay"
                         :time.sync="productionStartTime">
                </c-input>

                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSave" :fetching=fetchingSave>
                    Enregistrer
                  </button-spinner>
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEnd" :fetching=fetchingSaveAndLaunch>
                    Clôturer la production
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click="onSaveAndLaunch"
                                  :fetching=fetchingSaveAndLaunch>
                    Lancer la production
                  </button-spinner>
                </div>
              </b-col>
            </b-row>
            <p class="text-center" v-else>Aucune production sur cette parcelle.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {bTreeView} from "bootstrap-vue-treeview"
  import {Api, Toast, price} from "../../../helpers/index"

  class TreeNode {
    children = []
    parent = null

    constructor(props) {
      this.fill(props)
    }

    fill(props) {
      for (let key in props) {
        if (props.hasOwnProperty(key))
          this[key] = props[key]
      }
    }

    toJSON() {
      const obj = {}
      for (let key in this) {
        if (this.hasOwnProperty(key) && key !== "children")
          obj[key] = this[key]
      }
      // if (this.children && this.children.length)
      //   obj.children = this.children.map(child => child.toJSON())

      return obj
    }
  }

  export default {
    name: "ExploitationParcel",
    title: "PIA - Parcelle",
    components: {bTreeView},
    data() {
      return {
        parcelData: null,
        fetchingParcelData: false,

        productionFound: false,
        fetchingProductionData: false,
        error: null,
        creating: false,

        productionStatus: null,
        productionName: '',
        productionDescription: '',
        productionStartDay: '',
        productionStartTime: '',
        fetchingSave: false,
        fetchingSaveAndLaunch: false,

        activityDuration: '',
        activityCost: '',
        activityStaff: [],
        staffSearch: '',
        activityInputs: [],
        activityEquipments: [],
        fetchingStartActivity: false,

        fetchingStartStep: false,

        addNodeModal: false,
        toAdd: [],
        search: '',

        cultures: [],
        fetchingCultureList: null,
        cultureSteps: [],
        fetchingStepList: null,
        activities: [],
        fetchingActivityList: null,
        staff: [],
        fetchingStaffList: null,

        nodeTypes: {
          Production: {
            icon: "fa-cog",
            ctxMenuItems: [{code: "ADD_CHILD", label: "Ajouter des cultures"}],
            children: ["Culture"],
            modal: {
              noItemText: "Aucune culture sélectionnée.",
              searchPlaceholder: "Ajouter une culture...",
              autocompleteData: "cultures"
            }
          },
          Culture: {
            icon: "fa-leaf",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des étapes de culture"},
              {code: "DEL", label: "Supprimer la culture"}
            ],
            children: ["CultureStep"],
            modal: {
              noItemText: "Aucune étape sélectionnée.",
              searchPlaceholder: "Ajouter une étape...",
              autocompleteData: "cultureSteps"
            },
            refKey: "cultureId"
          },
          CultureStep: {
            icon: "fa-list-ol",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des activités"},
              {code: "DEL", label: "Supprimer l'étape de culture"}
            ],
            children: ["Activity"],
            modal: {
              noItemText: "Aucune activité sélectionnée.",
              searchPlaceholder: "Ajouter une activité...",
              autocompleteData: "activities"
            },
            refKey: "cultureStepId"
          }
          ,
          Activity: {
            icon: "fa-gavel",
            ctxMenuItems: [
              {code: "DEL", label: "Supprimer l'activité"}
            ],
            children: [],
            refKey: "activityId",
            properties: [
              {key: "duration", defaultValue: ''},
              {key: "cost", defaultValue: ''},
              {key: "staff", defaultValue: () => []},
              {
                key: "inputs",
                defaultValue: model => model.inputs.map(el => ({
                  inputId: el.id,
                  inputName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              },
              {
                key: "equipments",
                defaultValue: model => model.equipments.map(el => ({
                  equipmentId: el.id,
                  equipmentName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              }
            ]
          }
        },
        productionData: new TreeNode({
          id: 0, text: "Production", type: "Production"
        }),
        ctxMenuItems: [],
        selectedNode: null
      }
    },
    created() {
      this.fetchingCultureList = true
      Api.get('/exploitation/culture/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.cultures = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCultureList = false
        })

      this.fetchingStepList = true
      Api.get('/exploitation/culture/step/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.cultureSteps = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStepList = false
        })

      this.fetchingActivityList = true
      Api.get('/exploitation/activity/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.activities = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingActivityList = false
        })

      this.fetchingStaffList = true
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staff = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })

      this.fetchingParcelData = true
      Api.get('/exploitation/parcel', {
        exploitationId: this.exploitationId,
        parcelId: this.parcelId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.parcelData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = error
        })
        .then(() => {
          this.fetchingParcelData = false
        })

      this.fetchingProductionData = true
      Api.get('/exploitation/parcel/production', {
        exploitationId: this.exploitationId,
        parcelId: this.parcelId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const production = res.data.data
            if (production) {
              this.productionFound = true
              this.setProductionData(production)
            }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = error
        })
        .then(() => {
          this.fetchingProductionData = false
        })
    },
    mounted() {

    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      parcelId() {
        return this.$route.params.pid
      },
      productionId() {
        return this.productionData.productionId
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      treeData() {
        const productionData = this.productionData
        if (this.productionName)
          productionData.text = this.productionName

        return [productionData]
      },

      canCreate() {
        return !(this.productionFound || this.fetchingProductionData || this.creating)
      },

      cultureSelected() {
        return this.selectedNode && this.selectedNode.data.type === "Culture"
      },
      cultureStepSelected() {
        return this.selectedNode && this.selectedNode.data.type === "CultureStep"
      },
      activitySelected() {
        return this.selectedNode && this.selectedNode.data.type === "Activity"
      },
      selectedNodeStarted() {
        if (this.selectedNode && this.selectedNode.data.type !== "Production")
          return this.selectedNode.data.status && this.selectedNode.data.status.id === 3

        return this.productionStatus && this.productionStatus.id === 3
      },
      selectedNodeData() {
        return this.selectedNode && this.selectedNode.data ? this.selectedNode.data : {}
      },

      productionStartDate() {
        return this.productionStartDay + ' ' + this.productionStartTime
      },

      productionNameState() {
        return null
      },
      activityDurationState() {
        return null
      },
      activityCostState() {
        return null
      },
      activityInputQuantityState() {
        return [null]
      },
      activityEquipmentQuantityState() {
        return [null]
      },

      staffAutocompleteData() {
        return this.staff
      },

      // Modal
      addModalTitle() {
        if (!this.selectedNode)
          return ''

        return this.nodeTypes[this.selectedNode.data.type].ctxMenuItems[0].label
      },
      noItemText() {
        if (!this.selectedNode)
          return ''

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.noItemText : null
      },
      searchPlaceholder() {
        if (!this.selectedNode)
          return ''

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.searchPlaceholder : null
      },
      searchAutocompleteData() {
        if (!this.selectedNode)
          return []

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? this[modal.autocompleteData] : []
      },
      exploitationCurrency() {
        return price()
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      newProduction() {
        this.creating = true
      },

      onCtxMenuSelect({code}, node) {
        const args = code.split('-')
        switch (args[0]) {
          case "ADD_CHILD":
            this.addNodeModal = true
            break
          case "DEL":
            this.deleteNode(node.data.id)
            break
        }
        return true
      },
      onNodeSelect(node, selected) {
        if (selected) {
          this.selectedNode = node
          this.ctxMenuItems = this.nodeTypes[node.data.type].ctxMenuItems

          if (node.data.type === "Activity") {
            this.activityDuration = node.data.duration
            this.activityCost = node.data.cost
            this.activityStaff = node.data.staff
            this.activityInputs = node.data.inputs
            this.activityEquipments = node.data.equipments
          }
          else {
            this.activityDuration = ''
            this.activityCost = ''
            this.activityStaff = []
            this.activityInputs = []
            this.activityEquipments = []
          }
        }
      },
      deleteNode(id, root = this.productionData) {
        if (root.children) {
          const childrenLength = root.children.length
          root.children = root.children.filter(el => el.id !== id)
          if (childrenLength === root.children.length)
            root.children.forEach(node => this.deleteNode(id, node))
        }
      },
      findNode(id, root = this.productionData) {
        if (root.id === id)
          return root
        if (root.children) {
          let node
          for (let i = 0; i < root.children.length; i++) {
            node = this.findNode(id, root.children[i])
            if (node)
              return node
          }
        }
        return null
      },

      getProductionData() {
        return this.productionData.children.map(culture => ({
          cultureId: culture.cultureId,
          cultureSteps: culture.children.map(cultureStep => ({
            cultureStepId: cultureStep.cultureStepId,
            activities: cultureStep.children
          }))
        }))
      },
      setProductionData(data = {}) {
        this.productionStatus = data.status
        this.productionName = data.name
        this.productionDescription = data.description
        if (data.startDate) {
          const dateParts = data.startDate.split(' ')
          if (dateParts.length)
            this.productionStartDay = dateParts[0]
          if (dateParts.length >= 2)
            this.productionStartTime = dateParts[1]
        }
        this.productionData.productionId = data.id
        this.productionData.children = data.cultures.map(culture => {
          const cultureNode = new TreeNode()
          cultureNode.fill({
            ...culture,
            id: "Culture-" + culture.id,
            icon: this.nodeTypes.Culture.icon,
            type: "Culture",
            text: culture.name,
            cultureId: culture.id,
            cultureSteps: undefined,
            parent: this.productionData,
            children: culture.cultureSteps.map(cultureStep => {
              const stepNode = new TreeNode()
              stepNode.fill({
                ...cultureStep,
                id: "CultureStep-" + cultureStep.id,
                icon: this.nodeTypes.CultureStep.icon,
                type: "CultureStep",
                text: cultureStep.name,
                cultureStepId: cultureStep.id,
                activities: undefined,
                parent: cultureNode,
                children: cultureStep.activities.map(activity => new TreeNode({
                  ...activity,
                  id: "Activity-" + activity.id,
                  icon: this.nodeTypes.Activity.icon,
                  type: "Activity",
                  text: activity.name,
                  activityId: activity.id,
                  parent: stepNode,
                  children: [],
                  inputs: activity.inputs.map(input => ({
                    ...input,
                    inputId: input.id,
                    inputName: input.name
                  })),
                  equipments: activity.equipments.map(equipment => ({
                    ...equipment,
                    equipmentId: equipment.id,
                    equipmentName: equipment.name
                  }))
                }))
              })
              return stepNode
            })
          })
          return cultureNode
        })
      },
      onSubmit(route, fetchingAttribute, callback, data = {}) {
        this[fetchingAttribute] = true
        Api.post(route, {
          exploitationId: this.exploitationId,
          parcelId: this.parcelId,
          productionId: this.productionId,
          startDate: this.productionStartDate,
          name: this.productionName,
          description: this.productionDescription,
          cultures: this.getProductionData(),
          ...data
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              callback(res.data.data)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
            this.error = error
          })
          .then(() => {
            this[fetchingAttribute] = false
          })
      },
      onSave() {
        const route = this.productionFound ? 'save' : 'create'
        this.onSubmit('/exploitation/parcel/production/' + route, "fetchingSave", data => {
          this.productionStatus = data.status
          Toast.success('Production enregistrée avec succès !')
        })
      },
      onSaveAndLaunch() {
        const route = this.productionFound ? 'save' : 'create'
        this.onSubmit(`/exploitation/parcel/production/${route}-start`, "fetchingSaveAndLaunch", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Production démarrée avec succès !')
          }
        })
      },
      onEnd() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer la production "${this.productionName}" ?`))
          return

        this.onSubmit(`/exploitation/parcel/production/close`, "fetchingSaveAndLaunch", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Production clôturée avec succès !')
          }
        })
      },
      onUpdateActivity(noToast) {
        if (this.noActivitySelected)
          return

        this.selectedNode.data.duration = this.activityDuration
        this.selectedNode.data.cost = this.activityCost
        if (!noToast)
          Toast.success("Modification enregistrées avec succès !")
      },
      onStartActivity() {
        this.onUpdateActivity(true)
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureStepId: node.parent.cultureStepId,
          cultureId: node.parent.parent.cultureId
        }
        this.onSubmit("/exploitation/parcel/production/activity/start", "fetchingStartActivity", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Activité démarrée avec succès !')
          }
        }, data)
      },
      onEndActivity() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'activité "${this.selectedNode.data.text}" ?`))
          return

        this.onUpdateActivity(true)
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureStepId: node.parent.cultureStepId,
          cultureId: node.parent.parent.cultureId
        }
        this.onSubmit("/exploitation/parcel/production/activity/close", "fetchingStartActivity", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Activité clôturée avec succès !')
          }
        }, data)
      },

      onStartStep() {
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureStepId: node.cultureStepId,
          cultureId: node.parent.cultureId
        }
        this.onSubmit(`/exploitation/parcel/production/culture-step/start`, "fetchingStartStep", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Etape de culture démarrée avec succès !')
          }
        }, data)
      },

      onEndStep() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'étape "${this.selectedNode.data.text}" ?`))
          return

        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureStepId: node.cultureStepId,
          cultureId: node.parent.cultureId
        }
        this.onSubmit(`/exploitation/parcel/production/culture-step/close`, "fetchingStartStep", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Etape de culture clôturée avec succès !')
          }
        }, data)
      },

      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      },
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )

        if (item) {
          if (!this.activityStaff.includes(item))
            this.activityStaff.push(item)
        }
        else
          alert('Aucun élément trouvé !')

        this.clearStaffAutocompleteInput()
      },
      removeStaff(item) {
        this.activityStaff = this.activityStaff.filter(el => el.id !== item.id || el.user !== item.user)
      },

      // Modal
      clearSearchAutocompleteInput() {
        this.$refs.searchAutocompleteInput.inputValue = ''
        this.search = ''
      },
      onAddModalClose() {
        this.toAdd = []
        this.clearSearchAutocompleteInput()
      },
      removeItem(item) {
        this.toAdd = this.toAdd.filter(el => el.id !== item.id)
      },
      submitSearch(item) {
        if (!item && this.search.trim() !== '')
          item = this.searchAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.search.toLowerCase())
          )

        if (item) {
          if (!this.toAdd.includes(item))
            this.toAdd.push(item)
        }
        else
          alert('Aucun élément trouvé !')

        this.clearSearchAutocompleteInput()
      },
      onSubmitAdd() {
        if (!this.toAdd.length)
          return

        const parentType = this.nodeTypes[this.selectedNode.data.type]
        const type = this.nodeTypes[parentType.children[0]]
        this.toAdd.forEach(item => {
          const node = new TreeNode({
            id: parentType.children[0] + '-' + item.id,
            icon: type.icon,
            text: item.name,
            type: parentType.children[0],
            parent: this.selectedNode.data.ref
          })
          if (type.refKey)
            node[type.refKey] = item.id

          if (type.properties)
            type.properties.forEach(
              prop => node[prop.key] = typeof prop.defaultValue === "function"
                ? prop.defaultValue(item)
                : prop.defaultValue
            )

          this.selectedNode.data.children.push(node)
          this.selectedNode.expand()
        })

        this.addNodeModal = false
      }
    }
  }
</script>

<style scoped>
  .autocomplete {
    width: 100%;
    border-radius: 0;
  }

  .num-badge {
    width: 15px;
    height: 15px;
    margin-left: -3px;
    text-align: center;
    padding: auto 0;
  }

  .unit {
    width: 50px;
  }
</style>
